/** @jsxImportSource @emotion/react */
import { Theme } from "@emotion/react";
import { Interpolation } from "@emotion/serialize";
import { ColorFormat, ColorObject, Grid, Typography } from "@material-ui/core";
import { Color } from "@material-ui/lab";
import { CSSProperties } from "@material-ui/styles";
import { property } from "lodash";
import {
  DetailedHTMLProps,
  forwardRef,
  LegacyRef,
  MouseEventHandler,
  useState,
  VideoHTMLAttributes,
} from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import theme from "../theme";
import { backendMediaUrlParser, isValidHttpUrl } from "../utils";
import UploadIcon from "./icons/upload";

type DocumentPickerProps = {
  files?: any[];
  onDrop: DropzoneOptions["onDrop"];
  multiple?: boolean;
  maxFiles?: number;
  css?: Interpolation<Theme>;
  className?: string;
  border?: boolean;
  color?: CSSProperties["color"];
  hideContent?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onOpenRequest?: (openFunction: () => void) => void;
};

const DocumentPicker = forwardRef(
  (props: DocumentPickerProps, ref?: LegacyRef<HTMLInputElement>) => {
    const { files: _initialFiles, onDrop, multiple } = props;

    // const [files, setFiles] = useState<
    //   { id: string; url?: string; name?: string; externalId?: string }[]
    // >(
    //   (initialFiles || []).map((file) => {
    //     return {
    //       ...file,
    //       url: isValidHttpUrl(file.file)
    //         ? file.file || ""
    //         : backendMediaUrlParser(file.file),
    //     };
    //   })
    // );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
      onDrop,
      maxFiles: props.maxFiles || 1,
      maxSize: 20971520,
      multiple: props.multiple ?? false,
    });

    const inputProps = getInputProps();

    return (
      <div
        // container
        // direction="column"
        // spacing={2}
        {...getRootProps()}
        onClick={(e) => {
          // console.log(others.);

          if (props.onOpenRequest) {
            e.preventDefault();
            props.onOpenRequest(open);
          }

          if (!e.defaultPrevented) {
            open();
          }
        }}
        css={{ width: "100%", height: "100%" }}
      >
        <input {...inputProps} />
        <div
          className={props.className}
          css={{
            flex: 1,
            // border: "1px dashed rgba(239, 119, 5, 0.38);",ù
            display: "flex",
            alignItems: "center",
            zIndex: 3,
            justifyContent: "center",
            borderRadius: 12,
            marginTop: 16,

            height: 128,
            cursor: "pointer",
            transition: "500ms",
            backgroundColor: isDragActive ? "rgba(239, 119, 5, 0.12)" : "none",
            "&:hover": {
              // backgroundColor: "rgba(0, 0, 0, 0.87)",
            },
          }}
        >
          {isDragActive ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography children={"Release to upload"} color="primary" />
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              css={{
                opacity: props.hideContent ? 0 : 1,
                height: "100%",
                width: "100%",
                padding: 8,
                transition: "150ms",
                borderRadius: 12,
                backgroundImage: props.border
                  ? `url(
            "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(239, 119, 5, 0.38)' stroke-width='4' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e"
            )`
                  : undefined,
                ":hover": {
                  opacity: 1,
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.87)",
                  },
                },
              }}
            >
              <Grid item>
                <UploadIcon
                  size={24}
                  color={props?.color || theme.palette.primary.main}
                />
              </Grid>
              <Grid item>
                <Typography
                  children={"Upload file"}
                  color="primary"
                  css={{ color: props.color }}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
);

export default DocumentPicker;
