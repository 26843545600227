/** @jsxImportSource @emotion/react */

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Grow,
  TextField,
  Typography,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { Building, BuildingApi } from "belvillage-api-manager";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { BinIcon, DoneIcon } from "../../../components/BelVillageIcons";
import PlacePicker from "../../../components/PlacePicker";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { apiErrorParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

const initialState: Building = {
  id: "",
  name: "",
  hsycoUri: "",
  doorHsycoUri: "",
  place: {
    address: "",
    location: {
      lat: 0,
      lng: 0,
    },
  },
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();
  const { id } = useParams<any>();
  const buildingApi = useApi(BuildingApi);
  const { data, loading } = useFetch(buildingApi.getBuildingById, id, {
    skip: !id,
  });

  const [state, dispatch] = useFormInputReducer(initialState);

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({ open: true, editMode: true, editModelName: "buildings" });
    headerDispatch({ actionVisible: false });
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialState,
      });
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, ...others } = state;

    if (id) {
      //! EDIT
      buildingApi
        .editBuilding(id, { ...others })
        .then((value) => {
          if (exit) history.push(props.parentPath);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    } else {
      //! ADD
      buildingApi
        .createAdminBuilding({ ...others })
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  if ((!state || loading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justify="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="flex-start" alignContent="flex-start">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grow appear={true} in={true}>
            <Card>
              <CardContent
                css={{
                  ">*": {
                    marginBottom: 24,
                  },
                }}
              >
                <Typography
                  variant="h6"
                  children={(id ? "Edit" : "New") + " Building"}
                  gutterBottom
                />
                <TextField
                  {...valueChangeReducerHelper("name")}
                  label="Building name"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  {...valueChangeReducerHelper("hsycoUri")}
                  label="URI Hsyco"
                  fullWidth
                  variant="outlined"
                />
                <TextField
                  {...valueChangeReducerHelper("doorHsycoUri")}
                  label="Door Hsyco URI"
                  fullWidth
                  variant="outlined"
                />

                {(state?.place?.address != "" || !id) && (
                  <PlacePicker
                    variant="outlined"
                    placeholder={"Address"}
                    // initialRadius={fieldValue?.radius}
                    initialPlace={{
                      // place_id: "ChIJJ9m1406jpBIRu2PdpCmS7qU",
                      description: state?.place?.address || "",
                      //@ts-ignore
                      coordinates: state?.place?.location || { lat: 0, lng: 0 },
                    }}
                    onChange={(r, tmp) => {
                      if (typeof tmp === "object") {
                        dispatch({
                          field: "place",
                          value: {
                            address: tmp?.description || "",
                            location: tmp?.coordinates,
                          },
                        });
                      }
                    }}
                    autocompleteTypes={["geocode", "establishment"]}
                  />
                )}

                <Grid
                  container
                  justifyContent={id ? "space-between" : "flex-end"}
                >
                  {id && (
                    <Button
                      variant="text"
                      children="Delete"
                      startIcon={<BinIcon />}
                      onClick={() => {
                        buildingApi
                          .deleteBuilding(id)
                          .then(() => {
                            history.goBack();
                          })
                          .catch((error) =>
                            MLDialog.showSnackbar(apiErrorParser(error), {
                              variant: "error",
                            })
                          );
                      }}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    children="Confirm"
                    startIcon={<DoneIcon />}
                    onClick={() => {
                      saveDataToBackend(true);
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );

  function valueChangeReducerHelper(field: keyof typeof initialState) {
    return {
      value: state[field],
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}
