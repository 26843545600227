/** @jsxImportSource @emotion/react */

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Grow,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { Account, AccountApi, Booking } from "belvillage-api-manager";
import { DateTime } from "luxon";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useParams } from "react-router";
import {
  BinIcon,
  CloseIcon,
  DoneIcon,
} from "../../../components/BelVillageIcons";
import DocumentsIcon from "../../../components/icons/documents";
import UploadIcon from "../../../components/icons/upload";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import {
  apiErrorParser,
  backendMediaUrlParser,
  getObjectDeepValues,
  getObjectKeysRecursively,
  isValidHttpUrl,
} from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";
import { CheckInComponent, renderFirstAuthenticationComponent } from "./List";

const initialState = {
  id: "",
  name: "",
  firstAuthentication: false,
  email: { private: true, value: "" },
  booking: {
    code: "",
    checkIn: "",
    checkOut: "",
  },
  documents: [] as {
    id: string;
    file: string;
    name?: string;
    externalId?: string;
    url?: string;
  }[],
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();

  const { id, buildingId } = useParams<any>();
  const accountApi = useApi(AccountApi);
  const { data, loading, revalidate } = useFetch(
    accountApi.getAdminUserById,
    id,
    {
      skip: !id,
    }
  );

  const [state, dispatch] = useFormInputReducer(initialState);

  const [fileToDelete, setFileToDelete] = useState<string>();

  const onDrop = useCallback((acceptedFiles: any[]) => {
    console.log(acceptedFiles);
    accountApi
      .addUserDocument(id, acceptedFiles[0])
      .then((_x) => {
        MLDialog.showSnackbar("File uploaded successfully", {
          variant: "success",
        });
        revalidate();
      })
      .catch((err) => {
        MLDialog.showSnackbar(err?.message || "UNKNOWN ERROR", {
          variant: "error",
        });
      });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 20971520,
  });

  const [files, setFiles] = useState<
    { id: string; url?: string; name?: string; externalId?: string }[]
  >(
    (state?.documents || []).map((file) => {
      return {
        ...file,
        url: isValidHttpUrl(file.file)
          ? file.file || ""
          : backendMediaUrlParser(file.file),
      };
    })
  );
  // (state?.documents || []).map(
  //   (x) => backendMediaUrlParser(x?.file || "") || ""
  // )

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({ open: true, editMode: true, editModelName: "guests" });
    headerDispatch({ actionVisible: false });
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = getObjectKeysRecursively(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      setFiles(
        (data?.documents || []).map((file) => ({
          ...file,
          url: isValidHttpUrl(file.file || "")
            ? file.file || ""
            : backendMediaUrlParser(file.file),
        }))
      );

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialState,
      });
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, email, name, ..._others } = state;

    if (id) {
      //! EDIT
      accountApi
        .adminEditAccount(id, { email: email.value, name })
        .then((_value) => {
          if (exit) history.push(`/${buildingId}/guests`);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    } else {
      //! ADD
      accountApi
        .editUser()
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  if ((!state || loading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justify="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      alignItems="flex-start"
      alignContent="flex-start"
      css={{ padding: "36px 48px" }}
    >
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} xl={6}>
            <Grow appear={true} in={true}>
              <Card>
                <CardContent
                  css={{
                    ">*": {
                      marginBottom: 24,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    children={(id ? "Edit" : "New") + " Guest"}
                    gutterBottom
                  />
                  <TextField
                    {...valueChangeReducerHelper("name")}
                    label="Guest name"
                    fullWidth
                    variant="outlined"
                  />
                  <TextField
                    //@ts-ignore
                    {...valueChangeReducerHelper("email.value")}
                    label="Guest email"
                    fullWidth
                    variant="outlined"
                  />
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        //@ts-ignore
                        {...valueChangeReducerHelper("booking.code")}
                        label="Booking Code"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        //@ts-ignore
                        {...valueChangeReducerHelper("booking.room.roomNumber")}
                        label="Room Number"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        value={moment(
                          //@ts-ignore
                          valueChangeReducerHelper("booking.checkIn").value
                        ).format("MM-DD-YYYY")}
                        label="Check-in date"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        //@ts-ignore
                        value={returnBookingStayDays(state.booking)}
                        label="Stay days"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        value={moment(
                          //@ts-ignore
                          valueChangeReducerHelper("booking.checkOut").value
                        ).format("MM-DD-YYYY")}
                        label="Check-out date"
                        fullWidth
                        variant="standard"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography
                                      children="Logged in app?"
                                      variant="subtitle2"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                {renderFirstAuthenticationComponent(
                                  state as any as Account
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Card>
                          <CardContent>
                            <Grid
                              container
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item xs>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <Typography
                                      children="Check-in completed?"
                                      variant="subtitle2"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <CheckInComponent
                                  guest={state as any as Account}
                                />
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    justifyContent={id ? "space-between" : "flex-end"}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      children="Confirm"
                      startIcon={<DoneIcon />}
                      onClick={() => {
                        saveDataToBackend(true);
                      }}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
          <Grid item md={12} xl={4}>
            <Grow appear={true} in={true}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h6"
                    children={"Documents"}
                    gutterBottom
                  />

                  <Grid container direction="column" spacing={3}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                        {...getRootProps()}
                        css={{ height: 96 }}
                      >
                        <input {...getInputProps()} />
                        <div
                          css={{
                            flex: 1,
                            // border: "1px dashed rgba(239, 119, 5, 0.38);",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 8,
                            borderRadius: 12,
                            marginTop: 16,
                            cursor: "pointer",
                            transition: "500ms",
                            "&:hover": {
                              backgroundColor: "rgba(239, 119, 5, 0.12)",
                            },
                            backgroundColor: isDragActive
                              ? "rgba(239, 119, 5, 0.12)"
                              : "none",
                            backgroundImage: `url(
                              "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(239, 119, 5, 0.38)' stroke-width='4' stroke-dasharray='9' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e"
                            )`,
                          }}
                        >
                          {isDragActive ? (
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography
                                children={"Release to upload"}
                                color="primary"
                              />
                            </Grid>
                          ) : (
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <UploadIcon size={24} />
                              </Grid>
                              <Grid item>
                                <Typography
                                  children={"Upload file"}
                                  color="primary"
                                />
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                      <Typography
                        children={"Uploaded files"}
                        variant="overline"
                        css={{ opacity: 0.6 }}
                        component="span"
                      />

                      <Grid container direction="column">
                        {files.map((file, i) => {
                          console.log(file);

                          return (
                            <Grid
                              item
                              key={`file-${i}`}
                              css={{ marginBottom: 4 }}
                            >
                              <a
                                href={file.url}
                                target="_blank"
                                css={{ color: "white", textDecoration: "none" }}
                              >
                                <Card
                                  css={{
                                    transition: "50ms",
                                    "&:hover": {
                                      backgroundColor: "rgba(255,255,255,.12)",
                                    },
                                  }}
                                >
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Grid item>
                                        <DocumentsIcon color="#fff" />
                                      </Grid>
                                      <Grid item css={{ flex: 1 }}>
                                        {file.name}
                                      </Grid>
                                      {!file.externalId && (
                                        <Grid
                                          item
                                          children={
                                            <IconButton
                                              children={
                                                <BinIcon
                                                  width={17}
                                                  css={{ zIndex: 2 }}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    setFileToDelete(file.id);
                                                  }}
                                                />
                                              }
                                            />
                                          }
                                        />
                                      )}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </a>
                              <Dialog
                                open={fileToDelete === file.id}
                                onClose={() => setFileToDelete(undefined)}
                              >
                                <DialogTitle children={"Delete this file?"} />
                                <DialogContent>
                                  <Typography children="Are you sure to delete this file? This action is irreversible" />
                                </DialogContent>
                                <DialogActions>
                                  <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-around"
                                  >
                                    <Grid item css={{ flex: 1 }}>
                                      <Button
                                        startIcon={<CloseIcon width={14} />}
                                        children="Cancel"
                                        variant="text"
                                        onClick={() =>
                                          setFileToDelete(undefined)
                                        }
                                      />
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        startIcon={<BinIcon width={14} />}
                                        children="Delete"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          deleteFile(fileToDelete as string);
                                          setFileToDelete(undefined);
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </DialogActions>
                              </Dialog>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  function deleteFile(fileId: string) {
    accountApi
      .deleteUserDocument(id, fileId)
      .then((_x) => {
        MLDialog.showSnackbar("File deleted successfully", {
          variant: "success",
        });
        revalidate();
      })
      .catch((err) => {
        MLDialog.showSnackbar(err?.message || "Unknown error", {
          variant: "error",
        });
      });
  }

  function returnBookingStayDays(booking: Booking) {
    const checkOut = DateTime.fromISO(booking.checkOut);

    const differenceDays = checkOut
      .diffNow(["days", "hours", "seconds"])
      .toObject();

    return Math.round(differenceDays.days || 0);
  }

  function valueChangeReducerHelper(field: keyof typeof initialState) {
    const value = getObjectDeepValues(state, field);
    return {
      value,
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}
