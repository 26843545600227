/** @jsxImportSource @emotion/react */

import { Button, Collapse, Grow } from "@material-ui/core";
import { Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@material-ui/core";
import { AxiosError } from "axios";
import { CategoryApi } from "belvillage-api-manager";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { BinIcon, DoneIcon } from "../../../components/BelVillageIcons";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { apiErrorParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

const initialState = {
  id: "",
  name: "",
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();
  const { id } = useParams<any>();
  const categoryApi = useApi(CategoryApi);
  const { data, loading } = useFetch(categoryApi.getCategoryById, id, { skip: !id });

  const [state, dispatch] = useFormInputReducer(initialState);

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({ open: true, editMode: true, editModelName: "categories" });
    headerDispatch({ actionVisible: false });
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({ field: "UPDATE_STATE", value: availableDataFromInitialState as typeof initialState });
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, ...others } = state;

    if (id) {
      //! EDIT
      categoryApi
        .editCategory(id, { ...others })
        .then((value) => {
          if (exit) history.push(props.parentPath);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) => MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" }));
    } else {
      //! ADD
      categoryApi
        .createCategory({ ...others })
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) => MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" }));
    }
  }

  if ((!state || loading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justify="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container alignItems="flex-start" alignContent="flex-start">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grow appear={true} in={true}>
            <Card>
              <CardContent
                css={{
                  ">*": {
                    marginBottom: 24,
                  },
                }}
              >
                <Typography variant="h6" children={(id ? "Edit" : "New") + " Category"} gutterBottom />
                <TextField {...valueChangeReducerHelper("name")} label="Category name" fullWidth variant="outlined" />
                <Grid container justifyContent={id ? "space-between" : "flex-end"}>
                  {id && (
                    <Button
                      variant="text"
                      children="Delete"
                      startIcon={<BinIcon />}
                      onClick={() => {
                        categoryApi
                          .deleteCategory(id)
                          .then(() => {
                            history.goBack();
                          })
                          .catch((error) => MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" }));
                      }}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    children="Confirm"
                    startIcon={<DoneIcon />}
                    onClick={() => {
                      saveDataToBackend(true);
                    }}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grow>
        </Grid>
      </Grid>
    </Grid>
  );

  function valueChangeReducerHelper(field: keyof typeof initialState) {
    return {
      value: state[field],
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}
