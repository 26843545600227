/** @jsxImportSource @emotion/react */

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  TextField,
  Typography,
} from "@material-ui/core";
import { AxiosError } from "axios";
import { TutorialApi } from "belvillage-api-manager";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  BinIcon,
  CloseIcon,
  DoneIcon,
  PencilEditIcon,
} from "../../../components/BelVillageIcons";
import DocumentPicker from "../../../components/DocumentPicker";
import MLDialog from "../../../components/poppers";
import { useFormInputReducer } from "../../../hooks/useAdminReducer";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import theme from "../../../theme";
import { apiErrorParser, backendMediaUrlParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

const initialState = {
  id: "",
  name: "",
  thumbnail: undefined,
  description: "",
  video: undefined,
};

export function CreateEditView(props: { parentPath: string }) {
  const history = useHistory();

  const { id } = useParams<any>();

  const tutorialApi = useApi(TutorialApi);

  const { data, loading } = useFetch(tutorialApi.getAdminTutorialById, id, {
    skip: !id,
  });

  const [state, dispatch] = useFormInputReducer(initialState);

  const documentPickerRef = useRef(null);

  const [thumbnailRemovalOpen, setThumbnailRemovalOpen] = useState(false);
  const [thumbnailActionOpen, setThumbnailActionOpen] = useState(false);

  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();

  useEffect(() => {
    drawerDispatch({
      open: true,
      editMode: true,
      editModelName: "Tutorial videos",
    });
    headerDispatch({ actionVisible: false });
  }, []);

  useEffect(() => {
    if (data) {
      const initialKeys = Object.keys(initialState);

      const availableDataFromInitialState = {};
      initialKeys.forEach((key) => {
        //@ts-ignore
        availableDataFromInitialState[key] = data[key];
      });

      dispatch({
        field: "UPDATE_STATE",
        value: availableDataFromInitialState as typeof initialState,
      });
    }
  }, [data, dispatch]);

  function saveDataToBackend(exit?: boolean) {
    const { id, description, name, thumbnail, video } = state;
    console.log("~ ~ state", state);

    const fileArray = [
      typeof video === "string" ? undefined : video,
      typeof thumbnail === "string" ? undefined : thumbnail,
    ];

    console.log(
      "~ ~ id, name, description, [video, thumbnail]",
      id,
      name,
      description,
      fileArray
    );

    if (id) {
      //! EDIT
      tutorialApi
        .editTutorial(id, name, description, fileArray)
        .then((value) => {
          if (exit) history.push(props.parentPath);
          else
            MLDialog.showSnackbar("Saved successfully", {
              variant: "success",
            });
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    } else {
      //! ADD
      tutorialApi
        .createTutorial(name, description, fileArray)
        .then((value) => {
          MLDialog.showSnackbar("Created successfully", {
            variant: "success",
          });
          history.replace(props.parentPath + "/" + value.data?.id);
        })
        .catch((error: AxiosError) =>
          MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" })
        );
    }
  }

  if ((!state || loading || !data) && Boolean(id)) {
    return (
      <Grid container alignItems="center" css={{ height: "100%" }}>
        <Grid container justify="center">
          <CircularProgress size={60} variant="indeterminate" color="primary" />
        </Grid>
      </Grid>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container alignItems="flex-start" alignContent="flex-start">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grow appear={true} in={true}>
              <Card>
                <CardContent
                  css={{
                    ">*": {
                      marginBottom: 24,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    children={(id ? "Edit" : "New") + " Tutorial"}
                    gutterBottom
                  />
                  <Grid container alignItems="center" spacing={3}>
                    <Grid item>
                      <DocumentPicker
                        ref={documentPickerRef}
                        onDrop={(files: any) => {
                          dispatch({ field: "thumbnail", value: files[0] });
                        }}
                        border={!Boolean(state.thumbnail)}
                        hideContent={Boolean(state.thumbnail)}
                        onOpenRequest={(open) => {
                          if (state.thumbnail) {
                            
                          }
                        }}
                        onClick={(e) => {
                          if (state.thumbnail) {
                            e.preventDefault();
                            setThumbnailActionOpen(true);
                          } else {
                            (documentPickerRef.current as any).click();
                          }
                        }}
                        css={{
                          backgroundImage:
                            state?.thumbnail &&
                            `url("${
                              typeof state?.thumbnail === "string"
                                ? backendMediaUrlParser(state?.thumbnail)
                                : URL.createObjectURL(state?.thumbnail)
                            }")`,
                          backgroundPosition: "50% 50%",
                          backgroundSize: "contain",
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                          <TextField
                            {...valueChangeReducerHelper("name")}
                            label="Tutorial title"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs>
                          <TextField
                            {...valueChangeReducerHelper("description")}
                            label="Description"
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={4}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div
                    css={{ height: 128 }}
                    children={
                      <DocumentPicker
                        border
                        onDrop={(files, rej) => {
                          if (rej.length) {
                            MLDialog.showSnackbar(
                              rej[0]?.errors?.[0]?.message || "UNKNOWN ERROR",
                              { variant: "error" }
                            );
                          }
                          dispatch({ field: "video", value: files[0] });
                        }}
                      />
                    }
                  />
                  {state?.video && (
                    <video
                      css={{
                        border: `2px solid ${theme.palette.primary.main}`,
                        borderRadius: 12,
                        objectFit: "contain",
                        backgroundColor: "black",
                        height: 450,
                      }}
                      src={
                        typeof state?.video === "string"
                          ? backendMediaUrlParser(state?.video)
                          : URL.createObjectURL(state?.video)
                      }
                      controls
                      preload="metadata"
                      width="100%"
                      poster={
                        state?.thumbnail &&
                        (typeof state?.thumbnail === "string"
                          ? backendMediaUrlParser(state?.thumbnail)
                          : URL.createObjectURL(state?.thumbnail))
                      }
                      // poster={
                      //   state?.thumbnail &&
                      //   (typeof state?.thumbnail === "string"
                      //     ? backendMediaUrlParser(state?.thumbnail)
                      //     : URL.createObjectURL(state?.thumbnail))
                      // }
                    />
                    // <BelvillageMediaPlayer
                    //   css={{
                    //     border: `2px solid ${theme.palette.primary.main}`,
                    //     borderRadius: 12,
                    //   }}
                    //   src={
                    //     typeof state?.video === "string"
                    //       ? backendMediaUrlParser(state?.video)
                    //       : URL.createObjectURL(state?.video)
                    //   }
                    // />
                  )}
                </CardContent>
                <CardActions>
                  <Grid container>
                    <Grid item css={{ flex: 1 }}>
                      {id && (
                        <Button
                          variant="text"
                          children="Delete"
                          startIcon={<BinIcon />}
                          onClick={() => {
                            // categoryApi
                            //   .deleteCategory(id)
                            //   .then(() => {
                            //     history.goBack();
                            //   })
                            //   .catch((error) =>
                            //     MLDialog.showSnackbar(apiErrorParser(error), {
                            //       variant: "error",
                            //     })
                            //   );
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        children="Confirm"
                        startIcon={<DoneIcon />}
                        onClick={() => {
                          saveDataToBackend(false);
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={thumbnailRemovalOpen}
        onClose={() => setThumbnailRemovalOpen(false)}
      >
        <DialogTitle children="Remove Thumbnail" />
        <DialogContent>
          <Typography children="Are you sure you want to remove the thumbnail? This action is irreversible" />
        </DialogContent>
        <DialogActions>
          <Grid container alignItems="center">
            <Grid item css={{ flex: 1 }}>
              <Button
                children="Cancel"
                onClick={() => setThumbnailRemovalOpen(false)}
                startIcon={<CloseIcon width={14} />}
              />
            </Grid>
            <Grid item>
              <Button
                children="Remove"
                startIcon={<BinIcon width={14} />}
                variant="contained"
                color="primary"
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        open={thumbnailActionOpen}
        onClose={() => setThumbnailActionOpen(false)}
      >
        <DialogTitle children="Thumbnail Action" />
        <DialogContent>
          <Typography children="Select the thumbnail action" />
        </DialogContent>
        <DialogActions>
          <Button
            children="Remove Thumbnail"
            onClick={() => {
              setThumbnailActionOpen(false);
              setThumbnailRemovalOpen(true);
            }}
            startIcon={<BinIcon />}
          />
          <Button
            children="Change Thumbnail"
            startIcon={<PencilEditIcon />}
            variant="contained"
            color="primary"
            onClick={() => {
              setThumbnailActionOpen(false);

              // ((event as MouseEvent).target as HTMLElement).click();
              (documentPickerRef.current as any).click();
            }}
          />
        </DialogActions>
      </Dialog>
    </Container>
  );

  function valueChangeReducerHelper(field: keyof typeof initialState) {
    return {
      value: state[field],
      onChange: (event: any) => dispatch({ field, value: event.target.value }),
    };
  }
}
