/** @jsxImportSource @emotion/react */

import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grow,
  IconButton,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  TextField,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AxiosResponse } from "axios";
import {
  EditCreateHelpInputEmergencyNumbers,
  EmergencyNumber,
  Help,
  HelpApi,
  TutorialApi,
} from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import {
  BinIcon,
  DoneIcon,
  PencilEditIcon,
} from "../../../components/BelVillageIcons";
import MLDialog from "../../../components/poppers";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import useQueryParams from "../../../hooks/useQueryParams";
import theme from "../../../theme";
import { useApi, useFetch } from "../../../utils/api";
import TutorialAdminTable from "./components/TutorialAdminTable";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const tutorialApi = useApi(TutorialApi);
  const helpApi = useApi(HelpApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });
  const [dummyFilter, setDummyFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { path: matchedPath, url } = useRouteMatch();
  const { buildingId: id } = useParams<any>();

  const params = useQueryParams();

  const [openTab, setOpenTab] = useState(
    params.get("section") === "tutorials" ? 1 : 0
  );

  const [openNumberDialog, setOpenNumberDialog] = useState(false);
  useEffect(() => {
    drawerDispatch({ open: true, buildingSelected: true });
    headerDispatch({
      actionText: "ADD NEW",
      actionOnClick: () => {
        return openTab === 0 ? setOpenNumberDialog(true) : () => {};
      },
    });
  }, []);

  const {
    data: tutorials,
    rawData,
    loading,
  } = useFetch(tutorialApi.getAllAdminTutorials, transformToStringFrom(filter));

  const {
    data: buildingHelp,
    loading: loadingBuildingHelp,
    rawData: buildingHelpRawData,

    revalidate: revalidateBuildingHelp,
  } = useFetch(helpApi.getAdminBuildingHelp, id, { skip: !id });

  const [numberData, setNumberData] = useState<EmergencyNumber[] | undefined>(
    buildingHelp?.emergencyNumbers
  );
  const [numberToEdit, setNumberToEdit] = useState<EmergencyNumber>();

  const [visibleTutorialIds, setVisibleTutorialIds] = useState<string[]>(
    (buildingHelp?.videoShown || []).map((tutorial) => tutorial.id)
  );
  // console.log("~ ~ visibleTutorialIds", visibleTutorialIds);

  useEffect(() => {
    const newArray = (buildingHelp?.videoShown || []).map(
      (tutorial) => tutorial.id
    );
    // console.log("~ ~ newArray", newArray);
    setVisibleTutorialIds(newArray);

    if (buildingHelp) {
      setNumberData(buildingHelp.emergencyNumbers);
    }
  }, [tutorials, buildingHelp]);

  return (
    <Container maxWidth="xl">
      <Grid container direction="column">
        <Grid item xs={4}>
          <Tabs
            value={openTab}
            onChange={(_e, newValue) => {
              if (newValue === 1) {
                history.replace({
                  pathname: history.location.pathname,
                  search: "?section=tutorials",
                });
              } else {
                history.replace({
                  pathname: history.location.pathname,
                  search: "?section=phones",
                });
              }
              setOpenTab(newValue);
            }}
            textColor="primary"
            indicatorColor="primary"
            css={{ color: "#EE7705" }}
            variant="fullWidth"
          >
            <Tab label="Emergency telephone numbers" value={0} />
            <Tab label="Video tutorial" value={1} />
          </Tabs>
        </Grid>
        <Grid item xs>
          <Grid container spacing={1}>
            {openTab === 1 ? (
              <TutorialAdminTable
                data={tutorials}
                filterState={[filter, setFilter]}
                loading={loading}
                rawData={rawData}
                visibleTutorialIds={visibleTutorialIds}
                onAddVisibleTutorial={(tutorialId) => {
                  helpApi
                    .addTutorialToShownHelpVideos(id, tutorialId)
                    .then(() => {
                      revalidateBuildingHelp();
                    })
                    .catch((error) => {
                      MLDialog.showSnackbar(error.message || "UNKNOWN ERROR", {
                        variant: "error",
                      });
                    });
                }}
                onRemoveVisibleTutorial={(tutorialId) => {
                  helpApi
                    .removeTutorialFromShownHelpVideos(id, tutorialId)
                    .then(() => {
                      revalidateBuildingHelp();
                    })
                    .catch((error) => {
                      MLDialog.showSnackbar(error.message || "UNKNOWN ERROR", {
                        variant: "error",
                      });
                    });
                }}
              />
            ) : (
              <TelephoneNumbersAdminTable
                filterState={[dummyFilter, setDummyFilter]}
                loading={loadingBuildingHelp}
                rawData={buildingHelpRawData}
                data={buildingHelp}
                onEditNumber={(id) => {
                  setNumberToEdit(() => {
                    return numberData?.find((x) => x.id === id);
                  });

                  setOpenNumberDialog(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openNumberDialog}
        onClose={() => {
          setOpenNumberDialog(false);
          setNumberToEdit(undefined);
        }}
        fullWidth
      >
        <DialogTitle>
          {numberToEdit ? "Edit" : "Add"} Emergency Number
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={numberToEdit?.name || ""}
                onChange={(e) => {
                  setNumberToEdit({
                    id: numberToEdit?.id || "",
                    phone: numberToEdit?.phone || "",
                    name: e.target.value || "",
                  });
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Phone Number"
                variant="outlined"
                value={numberToEdit?.phone || ""}
                onChange={(e) => {
                  setNumberToEdit({
                    id: numberToEdit?.id || "",
                    name: numberToEdit?.name || "",
                    phone: e.target.value || "",
                  });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            css={{ margin: "0px 8px 0px 8px" }}
          >
            <Grid item css={{ flex: 1 }}>
              <Button
                color="secondary"
                children="delete"
                variant="outlined"
                startIcon={<BinIcon />}
                css={{
                  "&.MuiButton-outlined": {
                    borderColor: `${theme.palette.secondary.main} !important`,
                  },
                }}
                onClick={() => {
                  const newPhones = (numberData || []).map(
                    (emergencyNumber) => {
                      console.table({
                        emergencyNumber: emergencyNumber.id,
                        numberToEdit: numberToEdit?.id,
                      });

                      if (emergencyNumber.id === numberToEdit?.id) {
                        return undefined;
                      }
                      return emergencyNumber;
                    }
                  );

                  helpApi
                    .editHelp(id, {
                      emergencyNumbers: newPhones.filter(
                        Boolean
                      ) as EditCreateHelpInputEmergencyNumbers[],
                    })
                    .then(() => {
                      MLDialog.showSnackbar(
                        "Emergency number removed successfully",
                        { variant: "success" }
                      );
                      revalidateBuildingHelp();
                      setOpenNumberDialog(false);
                      setNumberToEdit(undefined);
                    })
                    .catch((err) => {
                      MLDialog.showSnackbar("Something went wrong", {
                        variant: "error",
                      });
                    });
                }}
              />
            </Grid>
            <Grid item>
              <Button
                children="save"
                variant="contained"
                color="primary"
                startIcon={<DoneIcon />}
                onClick={() => {
                  const newPhones = (numberData || []).map(
                    (emergencyNumber) => {
                      if (emergencyNumber.id === numberToEdit?.id) {
                        return numberToEdit;
                      }
                      return emergencyNumber;
                    }
                  );
                  if (!numberToEdit?.id && numberToEdit) {
                    newPhones.push(numberToEdit);
                  }

                  helpApi
                    .editHelp(id, {
                      emergencyNumbers:
                        newPhones as EditCreateHelpInputEmergencyNumbers[],
                    })
                    .then(() => {
                      MLDialog.showSnackbar(
                        "Emergency number updated successfully",
                        { variant: "success" }
                      );
                      revalidateBuildingHelp();
                      setOpenNumberDialog(false);
                      setNumberToEdit(undefined);
                    })
                    .catch((err) => {
                      MLDialog.showSnackbar("Something went wrong", {
                        variant: "error",
                      });
                    });
                }}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export type AdminTableBaseProps<T> = {
  loading: boolean;
  filterState: [
    IQueryFilter,
    React.Dispatch<React.SetStateAction<IQueryFilter>>
  ];
  rawData: AxiosResponse<T>;
  data?: T;
};

const TelephoneNumbersAdminTable = (
  props: AdminTableBaseProps<Help> & {
    onEditNumber: (id: string) => void;
  }
) => {
  const { loading, rawData, data, filterState } = props;
  return (
    <AdminTable
      loading={loading}
      count={parseCountFrom(rawData)}
      fullWidth
      occlusionHeight={60}
      data={data?.emergencyNumbers}
      headCells={TelephoneNumbersHeaderCells}
      queryFilter={filterState}
    >
      {(data) =>
        (data || []).map((phoneNumber, index) => {
          return (
            <Grow
              key={`phoneNumber-${index}`}
              appear={true}
              in={true}
              timeout={index * 300 + 300}
            >
              <TableRow onClick={() => props.onEditNumber(phoneNumber.id)}>
                <TableCell>{phoneNumber.name}</TableCell>
                <TableCell>{phoneNumber.phone}</TableCell>
                <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                  <IconButton
                    children={<PencilEditIcon />}
                    css={{
                      backgroundColor: "rgba(255, 255, 255, 0.12)",
                    }}
                  />
                </TableCell>
              </TableRow>
            </Grow>
          );
        })
      }
    </AdminTable>
  );
};

const TelephoneNumbersHeaderCells: IHeadCell[] = [
  { id: "name", label: "Number Label" },
  { id: "number", label: "Phone number" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
