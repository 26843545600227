/**@jsxImportSource @emotion/react */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  darken,
  Grid,
  IconButton,
  TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Preset } from "belvillage-api-manager";
import { MouseEvent } from "react";
import {
  DoneIcon,
  PencilEditIcon,
} from "../../../../components/BelVillageIcons";
import theme from "../../../../theme";

type PresetTileProps = {
  enabled: boolean;
  preset: Preset;
  label: string;
  expanded: boolean;
  onChange: (value: Preset) => void;
  onExpand: () => void;
  onSave: (data: { action: "save" | "delete" | "cancel" }) => void;
};

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-disabled": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
      },
    },
  },
})(TextField);

export default function PresetTile(props: PresetTileProps) {
  return (
    <Accordion
      expanded={props.expanded}
      onClick={() => {
        if (!props.expanded) props.onExpand();
      }}
      variant="outlined"
      css={{
        background: darken(alpha("#fff", 0.12), 0.1),
        border: 0,
        borderRadius: "12px",
      }}
    >
      <AccordionSummary
        css={{ ".MuiAccordionSummary-content": { height: 56 } }}
      >
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item css={{ flex: 1 }}>
            <CustomTextField
              value={props.label}
              disabled={!props.expanded}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item>
            {props?.expanded ? (
              <IconButton
                children={<DoneIcon width={18} height={18} />}
                css={{
                  backgroundColor: theme.palette.success.main,
                  ":hover": {
                    backgroundColor: darken(theme.palette.success.main, 0.1),
                  },
                }}
                onClick={() => {
                  props.onSave({ action: "cancel" });
                }}
              />
            ) : (
              <IconButton children={<PencilEditIcon />} />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{props?.preset?.name}</AccordionDetails>
    </Accordion>
  );
}
