/** @jsxImportSource @emotion/react */

import { Container, Grow, TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { BuildingApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { CloseIcon, DoneIcon } from "../../../components/BelVillageIcons";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { useApi, useFetch } from "../../../utils/api";
import moment from "moment";
import theme from "../../../theme";

import { Account } from "belvillage-api-manager/dist/api";
import ProhibitionIcon from "../../../components/icons/prohibition";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const buildingApi = useApi(BuildingApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { params, url } = useRouteMatch();

  const { buildingId } = params as any;

  useEffect(() => {
    drawerDispatch({ open: true, buildingSelected: true });
    headerDispatch({ actionVisible: false });
  }, []);

  const {
    data: accounts,
    rawData,
    loading,
  } = useFetch(
    buildingApi.getAdminBuildingGuests,
    buildingId,
    transformToStringFrom(filter),
    { skip: !buildingId }
  );

  return (
    <Container maxWidth="xl" css={{ height: "100%" }}>
      <AdminTable
        headCells={headCells}
        fullWidth
        occlusionHeight={100}
        loading={loading}
        queryFilter={[filter, setFilter]}
        count={parseCountFrom(rawData)}
        data={accounts}
        size="small"
      >
        {(data) => {
          return data?.map((guest, i) => {
            return (
              <Grow
                key={guest?.id}
                appear={true}
                in={true}
                timeout={i * 300 + 300}
              >
                <TableRow
                  key={guest?.id}
                  onClick={() => {
                    history.push(url + "/" + guest.id);
                  }}
                >
                  <TableCell>
                    <Typography variant="body1" color="textPrimary">
                      {guest.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="textPrimary">
                      {guest.surname}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="textPrimary">
                      {guest.email?.value}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color="textPrimary">
                      n. {guest.booking?.room.roomNumber}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {renderFirstAuthenticationComponent(guest)}
                  </TableCell>
                  <TableCell>
                    <Grid container direction="row" spacing={2}>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline" color="textPrimary">
                              CHECK-IN
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="textPrimary">
                              {moment(guest.booking?.checkIn).format(
                                "DD-MM-yyyy"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline" color="textPrimary">
                              STAY
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="textPrimary">
                              {moment(guest.booking?.checkOut).diff(
                                moment(guest.booking?.checkIn),
                                "days"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            <Typography variant="overline" color="textPrimary">
                              CHECK-OUT
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" color="textPrimary">
                              {moment(guest.booking?.checkOut).format(
                                "DD-MM-yyyy"
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <CheckInComponent guest={guest as Account} />
                  </TableCell>
                  <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                    <CircledEditIcon />
                  </TableCell>
                </TableRow>
              </Grow>
            );
          });
        }}
      </AdminTable>
    </Container>
  );
}

export function CheckInComponent(props: { guest: Account }) {
  const { guest } = props;

  let checkinStatus: "waiting" | "done" | "notDone";

  const checkInDate = moment(moment(guest.booking?.checkIn));

  if (checkInDate.isAfter(moment())) {
    checkinStatus = "waiting";
  } else {
    if (guest.booking?.doneCheckIn) {
      checkinStatus = "done";
    } else {
      checkinStatus = "notDone";
    }
  }

  let components;

  switch (checkinStatus) {
    case "done":
      components = [
        <Grid item>
          <DoneIcon color="#31D0AA" width={15} height={15} />
        </Grid>,
        <Grid item>
          <Typography
            css={{ color: theme.palette.success.main }}
            children="Check-in"
            variant="body2"
          />
        </Grid>,
      ];

      break;
    case "notDone":
      components = [
        <Grid item>
          <CloseIcon color="#ED4B9E" width={15} height={15} />
        </Grid>,
        <Grid item>
          <Typography
            variant="body2"
            css={{ color: theme.palette.error.main }}
            children="Check-in"
          />
        </Grid>,
      ];
      break;

    case "waiting":
      components = [
        <Grid item>
          <ProhibitionIcon color="#fff" opacity={0.38} size={15} />
        </Grid>,
        <Grid item>
          <Typography
            variant="body2"
            css={{ color: "#fff", opacity: 0.38 }}
            children="Waiting"
          />
        </Grid>,
      ];
      break;
    default:
      break;
  }

  return (
    <Grid container direction="row" spacing={1}>
      {components}
    </Grid>
  );
}

export function renderFirstAuthenticationComponent(guest: Account) {
  if (guest.firstAuthentication) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <DoneIcon color="#31D0AA" width={15} height={15} />
        </Grid>
        <Grid item>
          <Typography
            css={{ color: theme.palette.success.main }}
            children="Logged"
            variant="body2"
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <CloseIcon color="#ED4B9E" width={15} height={15} />
        </Grid>
        <Grid item>
          <Typography
            css={{ color: theme.palette.error.main }}
            children="Not Logged"
            variant="body2"
          />
        </Grid>
      </Grid>
    );
  }
}

const headCells: IHeadCell[] = [
  { id: "name", label: "Name" },
  { id: "surname", label: "Surname" },
  { id: "email.value", label: "Email" },
  { id: "booking.room.roomNumber", label: "Room Number" },
  { id: "firstAuthentication", label: "Login in app" },
  { id: "permanence", label: "Permanence", notSelectable: true },
  { id: "booking.doneCheckIn", label: "" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
