/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { Ability } from "@casl/ability";
import { Grid, useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import theme from "../src/theme";
import "./assets/css/main.css";
import BelDrawer from "./components/BelDrawer";
import { MLDialogProvider } from "./components/poppers";
import AdvicePage from "./pages/Admin/AdvicePage";
import BuildingPage from "./pages/Admin/BuildingPage";
import FAQPage from "./pages/Admin/FAQPage";
import GuestPage from "./pages/Admin/GuestPage";
import HelpPage from "./pages/Admin/HelpPage";
import BuildingNotificationPage from "./pages/Admin/NotificationBuildingPage";
import NotificationPage from "./pages/Admin/NotificationPage";
import RoomPage from "./pages/Admin/RoomPage";
import TutorialsPage from "./pages/Admin/TutorialsPage";
import EmailVerificationSentPage from "./pages/Auth/EmailVerificationSentPage";
import LoginPage from "./pages/Auth/LoginPage";
import LogoutPage from "./pages/Auth/LogoutPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import WelcomePage from "./pages/Auth/WelcomePage";

function App(props: any) {
  moment.locale("it");

  // const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  useEffect(() => {
    console.log(
      `

      ███╗   ███╗ █████╗ ██████╗ ██╗██╗      ██████╗ ███████╗████████╗
      ████╗ ████║██╔══██╗██╔══██╗██║██║     ██╔═══██╗██╔════╝╚══██╔══╝
      ██╔████╔██║███████║██████╔╝██║██║     ██║   ██║█████╗     ██║   
      ██║╚██╔╝██║██╔══██║██╔══██╗██║██║     ██║   ██║██╔══╝     ██║   
      ██║ ╚═╝ ██║██║  ██║██████╔╝██║███████╗╚██████╔╝██║        ██║   
      ╚═╝     ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝╚══════╝ ╚═════╝ ╚═╝        ╚═╝ 

    `
    );
  }, []);

  return (
    <Router>
      <CookiesProvider>
        <StylesProvider injectFirst>
          {/* {globalStyles} */}
          <ThemeProvider theme={theme}>
            <MLDialogProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <div className="App">
                <Switch>
                  <Route path="/">
                    <CitizenSide />
                  </Route>
                </Switch>
              </div>
            </MLDialogProvider>
          </ThemeProvider>
        </StylesProvider>
      </CookiesProvider>
    </Router>
  );
}

function CitizenSide() {
  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  return (
    <Grid container style={{ height: "100vh" }}>
      {/* <div style={{ width: "100%", height: "60px" }} /> */}
      <Grid container>
        <Grid item>
          <BelDrawer />
        </Grid>
        <Grid item xs>
          <Grid
            container
            alignItems={isMobile ? "flex-start" : "center"}
            css={{
              // height: `calc(100% - ${isMobile ? 40 : 60}px)`,
              height: `100%`,
              overflowY: "auto",
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #070707;",

              // paddingBottom: 40,
            }}
          >
            <div
              css={{
                height: "100%",
                width: "100%",
                // margin: 0,
                paddingTop: "76px",
              }}
            >
              <Switch>
                <Route path="/welcome">
                  <WelcomePage />
                </Route>

                <Route path="/login">
                  <LoginPage />
                </Route>

                <Route path="/register">
                  <RegisterPage />
                </Route>

                <ABACRoute
                  allowedIf={{ can: "readOne", model: "Account" }}
                  path="/email-verification-sent"
                >
                  <EmailVerificationSentPage />
                </ABACRoute>

                <ABACRoute
                  allowedIf={{ can: "readOne", model: "Account" }}
                  path="/verify-email/:token"
                >
                  <VerifyEmailPage />
                </ABACRoute>

                <ABACRoute
                  allowedIf={{ can: "readAll", model: "Building" }}
                  path="/buildings"
                >
                  <BuildingPage />
                </ABACRoute>

                {/* <ABACRoute
                  allowedIf={{ can: "readOne", model: "Account" }}
                  path="/categories"
                >
                  <CategoryPage />
                </ABACRoute> */}

                <ABACRoute
                  allowedIf={{ can: "readAll", model: "Building" }}
                  path="/notifications"
                >
                  <NotificationPage />
                </ABACRoute>

                <Route path="/register">
                  <RegisterPage />
                </Route>

                <Route path="/logout">
                  <LogoutPage />
                </Route>

                <Route path="/tutorials">
                  <TutorialsPage />
                </Route>

                <Route path="/faq">
                  <FAQPage />
                </Route>

                <Route path="/:buildingId">
                  <BuildingsSide />
                </Route>

                <Redirect to="/buildings" />
              </Switch>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BuildingsSide() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path + "/guests"}>
        <GuestPage />
      </Route>
      <Route path={path + "/rooms"}>
        <RoomPage />
      </Route>
      <Route path={path + "/advices"}>
        <AdvicePage />
      </Route>
      <Route path={path + "/help"}>
        <HelpPage />
      </Route>
      <Route path={path + "/notifications"}>
        <BuildingNotificationPage />
      </Route>

      <Redirect to={path + "/rooms"} />
    </Switch>
  );
}

type Actions = "create" | "readOne" | "readAll" | "update" | "delete";
interface PrivateRouteProps extends RouteProps {
  allowedIf?: { can: Actions; model: string };
  redirectTo?: string;
}

function ABACRoute({
  children,
  allowedIf,
  redirectTo,
  ...rest
}: PrivateRouteProps) {
  const [cookies] = useCookies();

  const belvillageToken = cookies["belvillageAdminAccessToken"];

  const rules = JSON.parse(localStorage.getItem("ability-rules") || "[]");

  const ability = new Ability(rules);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        belvillageToken &&
        (allowedIf ? ability.can(allowedIf.can, allowedIf.model) : true) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo || "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateQuestionnaire: (questionnaire: any) =>
      dispatch({ type: "UPDATE_QUESTIONNAIRE", payload: { questionnaire } }),
  };
};

export default connect(null, mapDispatchToProps)(App);
