/** @jsxImportSource @emotion/react */

import { Grow, TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CategoryApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { useApi, useFetch } from "../../../utils/api";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const categoryApi = useApi(CategoryApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { path: matchedPath } = useRouteMatch();

  useEffect(() => {
    drawerDispatch({ open: true });
    headerDispatch({ actionText: "ADD NEW" });
  }, []);

  const { data: categories, rawData, loading } = useFetch(categoryApi.allCategories, transformToStringFrom(filter));

  return (
    <Grid container>
      <Grid container spacing={1}>
        <AdminTable
          headCells={headCells}
          fullWidth
          occlusionHeight={60}
          loading={loading}
          queryFilter={[filter, setFilter]}
          count={parseCountFrom(rawData)}
          data={categories}
        >
          {(data) => {
            return data?.map((cat, i) => {
              return (
                <Grow key={cat?.id} appear={true} in={true} timeout={i * 300 + 300}>
                  <TableRow
                    key={cat?.id}
                    onClick={() => {
                      history.push(matchedPath + "/" + cat.id);
                    }}
                  >
                    <TableCell>
                      <Typography variant="body1" color="textPrimary">
                        {cat.name}
                      </Typography>
                    </TableCell>
                    <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                      <CircledEditIcon />
                    </TableCell>
                  </TableRow>
                </Grow>
              );
            });
          }}
        </AdminTable>
      </Grid>
    </Grid>
  );
}

const headCells: IHeadCell[] = [
  { id: "name", label: "Name" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
