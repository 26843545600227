/** @jsxImportSource @emotion/react */

import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import {
  Button,
  Drawer,
  Grid,
  List,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CalendarViewDayOutlined, EventOutlined } from "@material-ui/icons";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import CalendarTodayOutlined from "@material-ui/icons/CalendarTodayOutlined";
import DescriptionOutlined from "@material-ui/icons/DescriptionOutlined";
import EuroOutlined from "@material-ui/icons/EuroOutlined";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeft, Home, LogOut, Settings } from "react-feather";
import { connect } from "react-redux";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import AdminListItem from "./admin/AdminListItem";
import { ListItemRoute } from "./admin/NavBar";
import {
  AdviceIcon,
  BuildingIcon,
  CategoryIcon,
  EventIcon,
  FaqIcon,
  GuestIcon,
  HelpIcon,
  NotificationIcon,
  RoomIcon,
  ServiceIcon,
  TutorialVideoIcon,
} from "./BelVillageIcons";
import Header from "./Header";

export interface DrawerItemRoute {
  path?: string;
  label: string;
  icon?: EmotionJSX.Element;
  component?: EmotionJSX.Element;
  subpaths?: DrawerItemRoute[];
}

export interface BelDrawerParams {
  open?: boolean;
  editMode?: boolean;
  buildingSelected?: boolean;
  editModelName?: string;
  routes?: any[];
}

interface BelDrawerProps {
  drawer: BelDrawerParams;
}

function BelDrawer(props: BelDrawerProps) {
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const currentLocation = location.pathname;
  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  const buildingId = currentLocation.split("/")?.[1] || "/";

  const prevOpen = useRef(props?.drawer?.open);

  const drawerOpen = props?.drawer?.open;

  useEffect(() => {
    prevOpen.current = drawerOpen;
  }, [drawerOpen]);

  return (
    <Drawer
      css={{
        width: drawerOpen ? 266 : 0,
        height: "100%",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.05)",
      }}
      PaperProps={{ style: { width: 266 } }}
      variant="persistent"
      transitionDuration={prevOpen.current === drawerOpen ? 200 : 0}
      open={props?.drawer?.open}
    >
      <Header />
      <div style={{ width: "100%", height: "60px" }} />

      <Grid
        container
        css={{ position: "relative", overflowX: "hidden", height: "100%" }}
      >
        {renderMainRoutes()}
        {renderDetailRoutes()}
        {renderEditMenu()}

        <Button
          variant="text"
          css={{
            color: theme.palette.error.main,
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
          startIcon={<LogOut />}
          onClick={() => {
            history.push("/logout");
          }}
        >
          Logout
        </Button>
      </Grid>
    </Drawer>
  );

  function renderDetailRoutes() {
    return (
      <div
        css={{
          transition: theme.transitions.create(
            prevOpen.current === drawerOpen ? ["transform"] : []
          ),
          transform: `translateX(${
            props?.drawer?.buildingSelected ? 0 : -266
          }px)`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <List
          css={{
            ">": { color: "white" },
          }}
        >
          {/* <AdminListItem route={{ icon: <ArrowLeft />, label: "Back" }} onClick={() => history.goBack()} /> */}

          <AdminListItem
            style={{ backgroundColor: "#070707" }}
            onClick={() => {
              history.push("/buildings");
            }}
            route={{ icon: <ArrowLeft />, label: `Back to home` }}
          />

          {getBuildingDrawerRoutes(buildingId).map((route, index) => (
            <AdminListItem
              key={index}
              onClick={
                route?.path
                  ? () => {
                      history.push(route?.path || "");
                    }
                  : undefined
              }
              route={route}
            />
          ))}
        </List>
      </div>
    );
  }

  function renderMainRoutes() {
    return (
      <div
        css={{
          transition: theme.transitions.create(
            prevOpen.current === drawerOpen ? ["transform"] : []
          ),
          transform: `translateX(${
            props?.drawer?.buildingSelected || props?.drawer?.editMode === true
              ? 266
              : 0
          }px)`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <List>
          <div css={{ height: 24, width: 24 }} />

          {getMainDrawerRoutes().map((route, index) => (
            <AdminListItem
              key={index}
              onClick={
                route?.path
                  ? () => {
                      history.push(route?.path || "");
                    }
                  : undefined
              }
              route={route}
            />
          ))}
        </List>
      </div>
    );
  }
  function renderEditMenu() {
    return (
      <div
        css={{
          transition: theme.transitions.create(
            prevOpen.current === drawerOpen ? ["transform"] : []
          ),
          transform: `translateX(${
            props?.drawer?.editMode !== true ? -266 : 0
          }px)`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        <List>
          <AdminListItem
            style={{ backgroundColor: "#070707" }}
            onClick={() => {
              history.goBack();
            }}
            route={{
              icon: <ArrowLeft />,
              label: `Back to ${props?.drawer?.editModelName || ""}`,
            }}
          />
        </List>
      </div>
    );
  }
}

function getMainDrawerRoutes(): ListItemRoute[] {
  return [
    { icon: <BuildingIcon />, label: "Buildings", path: "/buildings" },
    // { icon: <CategoryIcon />, label: "Categories", path: "/categories" },
    {
      icon: <NotificationIcon />,
      label: "Notifications",
      path: "/notifications",
    },
    { icon: <FaqIcon />, label: "F.A.Q.", path: "/faq" },
    {
      icon: <TutorialVideoIcon />,
      label: "Video tutorial",
      path: "/tutorials",
    },
  ];
}

function getBuildingDrawerRoutes(buildingId: string): ListItemRoute[] {
  return [
    {
      icon: <GuestIcon />,
      label: "Guests",
      path: "/" + buildingId + "/guests",
    },
    {
      icon: <AdviceIcon />,
      label: "Advices",
      path: "/" + buildingId + "/advices",
    },
    {
      icon: <EventIcon />,
      label: "Events",
      path: "/" + buildingId + "/events",
    },
    {
      icon: <ServiceIcon />,
      label: "Services",
      path: "/" + buildingId + "/services",
    },
    { icon: <HelpIcon />, label: "Help", path: "/" + buildingId + "/help" },
    { icon: <RoomIcon />, label: "Rooms", path: "/" + buildingId + "/rooms" },
    {
      icon: <NotificationIcon />,
      label: "Notifications",
      path: "/" + buildingId + "/notifications",
    },
  ];
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    drawer: state.updates.drawer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateHistory: (name: any, values: any) =>
      dispatch({ type: "NEW_STEP", payload: values }),
    restoreSection: () => dispatch({ type: "RESTORE_SECTION", payload: "" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BelDrawer);
