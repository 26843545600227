/** @jsxImportSource @emotion/react */

import { Container, Grow, TableCell, TableRow } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { RoomApi } from "belvillage-api-manager";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AdminTable, {
  defaultQueryFilter,
  IHeadCell,
  IQueryFilter,
  parseCountFrom,
  transformToStringFrom,
} from "../../../components/admin/AdminTable";
import { CircledEditIcon } from "../../../components/CircledEditIcon";
import { useDrawerDispatchHelper } from "../../../hooks/useDrawerDispatchHelper";
import { useHeaderDispatchHelper } from "../../../hooks/useHeaderDispatchHelper";
import { backendMediaUrlParser } from "../../../utils";
import { useApi, useFetch } from "../../../utils/api";

function List() {
  const history = useHistory();
  const drawerDispatch = useDrawerDispatchHelper();
  const headerDispatch = useHeaderDispatchHelper();
  const roomApi = useApi(RoomApi);
  const [filter, setFilter] = useState<IQueryFilter>({
    ...defaultQueryFilter,
  });

  const { params, url } = useRouteMatch();

  const { buildingId } = params as any;

  useEffect(() => {
    drawerDispatch({ open: true, buildingSelected: true });
    headerDispatch({ actionText: "ADD NEW" });
  }, []);

  const {
    data: rooms,
    rawData,
    loading,
  } = useFetch(
    roomApi.allAdminRoomsByBuildingId,
    buildingId,
    transformToStringFrom(filter)
  );

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid container spacing={1}>
          <AdminTable
            headCells={headCells}
            fullWidth
            occlusionHeight={60}
            loading={loading}
            queryFilter={[filter, setFilter]}
            count={parseCountFrom(rawData)}
            data={rooms}
          >
            {(data) => {
              return data?.map((room, i) => {
                return (
                  <Grow
                    key={room?.id}
                    appear={true}
                    in={true}
                    timeout={i * 300 + 300}
                  >
                    <TableRow
                      key={room?.id}
                      onClick={() => {
                        history.push(url + "/" + room.id);
                      }}
                    >
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          {room.roomNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <img src={backendMediaUrlParser(room.image)} />
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          {room.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary">
                          {room.type}
                        </Typography>
                      </TableCell>
                      <TableCell css={{ textAlign: "right", paddingRight: 24 }}>
                        <CircledEditIcon />
                      </TableCell>
                    </TableRow>
                  </Grow>
                );
              });
            }}
          </AdminTable>
        </Grid>
      </Grid>
    </Container>
  );
}

const headCells: IHeadCell[] = [
  { id: "roomNumber", label: "Room Number" },
  { id: "image", label: "Image" },
  { id: "name", label: "Name" },
  { id: "type", label: "Type of room" },
  { id: "edit", label: "", notSelectable: true },
];

export default List;
