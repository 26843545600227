/** @jsxImportSource @emotion/react */

import { LatLngLiteral } from "@googlemaps/google-maps-services-js";
import {
  fade,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { useCallback, useEffect, useRef, useState } from "react";
import theme from "../theme";
import PlacesGeocodeAutocompleteInput, {
  AutocompleteType,
  GeocodePlaceResult,
} from "./PlacesGeocodeAutocompleteInput";

const libraries: Libraries = ["places"];

const PlacePicker = (props: {
  variant?: "standard" | "filled" | "outlined";
  onChange?: (radius: number, place: GeocodePlaceResult) => void;
  withRadius?: boolean;
  readOnly?: boolean;
  placeholder?: string | false;
  autocompleteTypes?: AutocompleteType[];
  label?: string;
  restrictedTo?: string[];
  // initialValues?: { place: GeocodePlaceResult; radius?: number };
  initialPlace?: Partial<GeocodePlaceResult>;
  initialCenter?: LatLngLiteral;
  initialRadius?: number;
  mapOnly?: boolean;
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_PUBLIC_MAPS_API_KEY as string,
    libraries: libraries,
  });

  const [map, setMap] = useState<GoogleMap>();

  const onLoad = useCallback(function callback(map) {
    // const bounds = new (window as any).google.maps.LatLngBounds(center.lat, center.lng);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(undefined);
  }, []);

  //? end of gmaps lib setuo

  const [_radius, setRadius] = useState<number>(
    props.initialRadius || props.withRadius ? 10 : 0
  );
  const [center, setCenter] = useState<LatLngLiteral | undefined>(
    props?.initialCenter || props.initialPlace?.coordinates
  );
  const [place, setPlace] = useState<GeocodePlaceResult>(
    props.initialPlace ||
      (props.initialCenter?.lat
        ? ({ coordinates: props.initialCenter } as any)
        : undefined)
  );

  const radius = Math.max(1, _radius);

  useEffect(() => {
    if (props.withRadius && !_radius) return;
    if (center?.lat && center?.lng) {
      if (
        center.lat !== props.initialCenter?.lat ||
        center.lng !== props.initialCenter?.lng ||
        place?.description !== props.initialPlace?.description ||
        (props.withRadius && _radius !== props.initialRadius)
      ) {
        props.onChange?.(_radius, place);
      }
    }
  }, [center, _radius, place]);

  const inset = 0; //1;

  const circle = useRef<any>();

  function getBaseLog(x: number, y: number) {
    const res = Math.log(y) / Math.log(x);
    // console.log("BASELOG: ", res);

    return res;
  }

  let zoom = 19.35;
  if (props.withRadius === true) {
    zoom = place ? getBaseLog(2, 40000 / ((radius * 10) / 2)) : 1;
  } else {
    zoom = 17.0000001;
  }

  const canShowGoogleMap = isLoaded && center?.lat && center?.lng;

  // console.log("🟩 place", props.initialPlace);
  // console.log("-->", props.initialRadius);
  // console.log("--> ", isLoaded ? center : "loading");

  return (
    <Grid container direction="column" spacing={2}>
      {!props.mapOnly && (
        <Grid item xs>
          <FormControl css={{ width: "100%" }}>
            <PlacesGeocodeAutocompleteInput
              readOnly={props.readOnly}
              gmapsReady={isLoaded}
              label={
                props.label !== undefined
                  ? props.label
                    ? props.label
                    : "indirizzo"
                  : undefined
              }
              placeholder={props.placeholder || "Trova l'indirizzo preciso"}
              place={place}
              variant={props.variant || "outlined"}
              withRadius={props.withRadius}
              onChange={(place) => {
                // console.log("COORDINATES: ", place.coordinates, place);
                setPlace(place);
                setCenter(place.coordinates);
              }}
              restrictedTo={props.restrictedTo}
              autocompleteTypes={props.autocompleteTypes}
            />
          </FormControl>
        </Grid>
      )}

      {place && props.withRadius && !props.mapOnly && (
        <Grid item xs>
          <TextField
            label="raggio di azione (km)"
            placeholder="km"
            variant={props.variant || "outlined"}
            type="number"
            fullWidth
            value={_radius}
            // InputProps={{ inputProps: { min: minRadius } }}
            InputProps={{ inputProps: { max: 1000 } }}
            onChange={({ target: { value } }) =>
              setRadius(value ? parseInt(value) : 0)
            }
          />
        </Grid>
      )}

      <Grid
        item
        xs
        style={place ? undefined : { visibility: "hidden", height: 0 }}
      >
        <FormControl css={{ width: "100%" }}>
          <InputLabel
            children={!props.withRadius ? "" : "anteprima del raggio"}
          />
          <div
            css={{
              pointerEvents: "none",
              position: "relative",
              height: 302,
              borderRadius: 20,
              overflow: "hidden",
              "*": { outline: "none" },
            }}
          >
            {Boolean(canShowGoogleMap) && (
              <GoogleMap
                mapContainerStyle={{
                  position: "absolute",
                  left: -inset,
                  top: -inset,
                  right: -inset,
                  bottom: -inset,
                }}
                center={{ lat: center?.lat, lng: center?.lng }}
                zoom={giveMeZoom(zoom)}
                onLoad={onLoad}
                onUnmount={onUnmount}
                // onDragEnd={() => console.log(1, map)}
                // onBoundsChanged={() => console.log(2, map)}
                options={{
                  // zoomControl: true
                  // overviewMapControl: false
                  scrollwheel: false,
                  disableDoubleClickZoom: true,
                  disableDefaultUI: true,
                }}
              >
                {props.withRadius && (
                  <Circle
                    ref={(r) => (circle.current = r)}
                    options={{
                      strokeColor: fade(theme.palette.primary.main, 1),
                      fillColor: fade(theme.palette.primary.main, 0.9),
                    }}
                    center={center}
                    radius={radius * 1000}
                    draggable
                    onDragEnd={(drag) => {
                      const latLng =
                        circle.current?.state?.circle?.center || drag.latLng;
                      const newCenter = {
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                      };
                      // console.log("NewCenter: ", newCenter);
                      setCenter(newCenter);
                    }}
                  />
                )}
                {!props.withRadius && <Marker position={center} />}
              </GoogleMap>
            )}
          </div>
        </FormControl>
      </Grid>
    </Grid>
  );
};

function giveMeZoom(zoom: number) {
  // console.log("GIMMEZOOM!: ", zoom);
  return zoom;
}

export default PlacePicker;
