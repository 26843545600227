import { red } from "@material-ui/core/colors";
import { alpha, createTheme, darken } from "@material-ui/core/styles";
import R, { hexWithOpacity } from "./R";

// Create a theme instance.
const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#EF7705",
      contrastText: "#fff",
      // light: "#0C3A76",
    },
    secondary: {
      main: "#FCF5ED",
      // light: "#60E2AA",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255, 255, 255, 0.6)",
    },
    error: {
      main: "#ED4B9E",
    },
    success: {
      main: "#31D0AA",
    },
    warning: {
      main: "#ED4B9E",
    },

    background: {
      default: "#070707",
    },
  },
  overrides: {
    MuiSnackbar: {
      root: {
        borderRadius: 0,
        color: "green",
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        borderRadius: "5px !important",
        border: "1px solid #e7e7e7",
      },
    },
    MuiTabs: {
      root: {
        borderRadius: "4px",
        backgroundColor: "#ffffff0d",
      },
      flexContainer: {
        width: "auto",
      },
    },
    MuiTab: {
      root: {
        padding: 0,
        transition: "100ms",
        "&.Mui-selected": {
          backgroundColor: "#EF770533",
        },
      },
      textColorPrimary: {
        color: "#EF7705",
      },
    },
    MuiTableRow: {
      root: {
        position: "relative",
        background: "#242424",
        borderRadius: 12,
        border: "none",
        "&:hover": {
          background: "#313131",
          cursor: "pointer",
          "&::before": {
            background:
              "linear-gradient(180deg, #313131 0%, rgba(255, 255, 255, 0) 17.19%, rgba(255, 255, 255, 0) 85.42%, #313131 100%)",
          },
        },
        "&::before": {
          content: '"mabiloft"',
          zIndex: 0,
          position: "absolute",
          top: 4,
          left: 10,
          color: "transparent",
          width: "calc(100% - 20px)",
          height: "calc(100% - 8px)",
          background:
            "linear-gradient(180deg, #242424 0%, rgba(255, 255, 255, 0) 17.19%, rgba(255, 255, 255, 0) 85.42%, #242424 100%)",
        },
      },
      head: {
        "&:hover": {
          background: "initial",
          cursor: "initial",
        },
        "&::before": {
          content: '"mabiloft"',
          position: "absolute",
          width: 0,
          top: 0,
          left: 0,
          color: "transparent",
          background:
            "linear-gradient(180deg, #242424 0%, rgba(255, 255, 255, 0) 17.19%, rgba(255, 255, 255, 0) 85.42%, #242424 100%)",
        },
      },
    },
    MuiTableCell: {
      head: {
        borderTop: "none",
        borderBottom: "none",
      },
      root: {
        background: "transparent",
        borderTop: "4px solid #141414",
        borderBottom: "4px solid #141414",
        borderRadius: 8,
        marginBottom: 8,
      },
    },
    MuiTooltip: {
      tooltip: {
        pointerEvents: "all",
        backgroundColor: R.colors.deepBlue,
        padding: 18,
        borderRadius: 20,
      },
      arrow: {
        color: R.colors.deepBlue,
      },
    },
    MuiLinearProgress: {
      determinate: {
        backgroundColor: "#e7e7e7",
      },
    },
    MuiButton: {
      root: {
        padding: "12px 24px",
        borderRadius: 10,
        textTransform: "uppercase",
        "&$disabled": {
          background: "rgba(255, 255, 255, 0.12) !important",
          color: "rgba(255, 255, 255, 0.38)",
        },
      },
      outlined: {
        padding: "15px 24px",
        borderColor: "#EF7705 !important",
        color: "#EF7705",
        "&:hover": {
          background: "rgba(255, 255, 255, 0.12) !important",
        },
      },
      contained: {
        padding: "17px 24px",
      },
      text: {
        padding: "12px 24px",
        "&$disabled": {
          background: "transparent !important",
          color: "#B6B6B6 !important",
        },
      },
      containedPrimary: {
        background: "#EF7705",
        boxShadow:
          "0px 6px 12px rgba(181, 90, 4, 0.6), inset 0px -2px 4px rgba(153, 76, 3, 0.48)",
        "&:hover": {
          background: "#F18A28",
          boxShadow:
            "0px 6px 12px rgba(181, 90, 4, 0.6), inset 0px -2px 4px rgba(153, 76, 3, 0.48)",
        },
      },
      containedSecondary: {
        boxShadow: "inset 0px -1px 0.5px rgba(14, 14, 44, 0.4)",
        color: "#EF7705",
        "&:hover": {
          background: "#FCF6EF !important",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "inherit",
      },
      outlined: {
        backgroundColor: hexWithOpacity("#FFFFFF", 0.5),
        border: `2px solid ${hexWithOpacity("#FFFFFF", 0.5)}`,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 6,
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
        background: "rgba(255, 255, 255, 0.08)",
      },
    },

    MuiCardContent: {
      root: {
        padding: 24,
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: "flex-end",
      },
    },
    MuiChip: {
      root: {
        marginRight: 8,
        marginTop: 4,
        marginBottom: 4,
        backgroundColor: hexWithOpacity("#FE3D3D", 0.04),
        color: "#FE3D3D",
        fontSize: 12,
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: 'transparent',
        // backgroundColor: R.colors.deepBlue,
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #070707",
        color: "white",
        maxWidth: 266,
        width: R.dimens.drawerWidth,
      },
      paperAnchorDockedLeft: {
        borderRight: "0px",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          "&::before": {
            border: "0",
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 12,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 12,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        color: "red",
        backgroundColor: hexWithOpacity("#E7ECF1", 0.3),
        "&:hover": {
          backgroundColor: hexWithOpacity("#E7ECF1", 0.4),
        },
        "&.Mui-focused": {
          backgroundColor: hexWithOpacity("#E7ECF1", 0.5),
        },
      },
      input: {
        padding: "16px 12px 15px",
        fontSize: 14,
        lineHeight: 1.6,
        color: R.colors.primary,
      },
      multiline: {
        padding: "16px 12px 15px",
      },
      underline: {
        "&::before, &::after": {
          border: 0,
        },
        "&:hover": {
          border: 0,
          "&::before": {
            border: 0,
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: R.colors.deepBlue,
      },

      // root: {
      //   backgroundColor: hexWithOpacity(R.colors.primary, 0.06),
      //   color: "rgba(9, 64, 116, .6)",
      //   borderRadius: 12,
      //   "& .MuiIconButton-label": {
      //     width: 28,
      //     height: 28,
      //   },
      //   "@media screen and (max-width: 959px)": {
      //     padding: 12,
      //     "& .MuiIconButton-label": {
      //       width: 16,
      //       height: 16,
      //     },
      //   },
      // },
    },
    MuiListItem: {
      root: {
        marginBottom: 10,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 12,
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0.1,
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 24,
        padding: 0,
        margin: 8,
        borderRadius: 16,
      },

      switchBase: {
        transition: "150ms",
        padding: 2,
        "&$checked": {
          transform: "translateX(18px)",
          color: "#fff",
          "& + $track": {
            border: "1px solid rgba(239, 119, 5, 0.38)",
            backgroundColor: "#EF7705",
            opacity: 1,
          },
        },
        "&$disabled": {
          "& $thumb": {
            backgroundColor: alpha("#fff", 0.38),
            color: alpha("#fff", 0.38),
          },
          "&$checked": {
            transform: "translateX(18px)",
            "& + $track": {
              border: "1px solid rgba(255, 255, 255, 0.38)",
              backgroundColor: alpha("#fff", 0.38),
            },
          },
        },
      },
      checked: {},

      track: {
        border: "1px solid rgba(255, 255, 255, 0.38)",
        backgroundColor: "#EF7705",
        opacity: 1,
      },

      thumb: {
        backgroundColor: "#fff",
        color: "#fff",
        height: 20,
        width: 20,
        "& + checked": {
          backgroundColor: "#fff",
          color: "#fff",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
        color: "rgba(0, 0, 0, .6)",
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 12,
      },
      list: {
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 12,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 20,
        padding: 30,
        backgroundColor: "#070707",
      },
      container: {
        backgroundColor: "#81818126",
      },
    },

    MuiCheckbox: {
      root: {
        height: 18.5,
        width: 18.5,
        border: "1.5px solid orange",
        borderRadius: 4,
        "& svg": {
          "& path": {
            opacity: 0,
            boxShadow: "0px 4px 8px rgba(28, 37, 44, 0.08)",
            color: "#E7E7E7",
            d: 'path("")',
            // d: 'path("M13 5.67688H23V2.67688H13V5.67688ZM26.5 9.17688V19.1769H29.5V9.17688H26.5ZM23 22.6769H13V25.6769H23V22.6769ZM9.5 19.1769V9.17688H6.5V19.1769H9.5ZM13 22.6769C11.067 22.6769 9.5 21.1099 9.5 19.1769H6.5C6.5 22.7667 9.41015 25.6769 13 25.6769V22.6769ZM26.5 19.1769C26.5 21.1099 24.933 22.6769 23 22.6769V25.6769C26.5899 25.6769 29.5 22.7667 29.5 19.1769H26.5ZM23 5.67688C24.933 5.67688 26.5 7.24388 26.5 9.17688H29.5C29.5 5.58703 26.5899 2.67688 23 2.67688V5.67688ZM13 2.67688C9.41015 2.67688 6.5 5.58703 6.5 9.17688H9.5C9.5 7.24388 11.067 5.67688 13 5.67688V2.67688Z")',
          },
        },
        "&$checked": {
          backgroundColor: "green",
          "& svg": {
            width: 4,
            height: 8,
            border: "1.5px solid white",
            borderTop: 0,
            borderLeft: 0,
            transform: "rotate(45deg)",
            // "& path": {
            //   transform: "translate(7px, 7px)",
            //   fill: "none",
            //   stroke: "red",
            //   strokeWidth: 2,
            //   strokeLinecap: "round",
            //   d: 'path("M8.75 1.67688L3.59375 7.30188L1.25 4.74506")',
            // },
          },
        },
      },
      checked: {},
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      fontWeight: 500,
    },
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -1.5,
    },
    h2: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 48,
      fontWeight: 500,
    },
    h4: {
      fontSize: 34,
      letterSpacing: 0.25,
      // "@media screen and (max-width: 959px)": {
      //   fontSize: 20,
      //   letterSpacing: 0.5,
      // },
    },
    h5: {
      fontSize: 24,
      lineHeight: "29px",
    },
    h6: {
      fontSize: 20,
      lineHeight: "29px",
      fontWeight: 600,
      letterSpacing: 0.15,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 600,
      // "@media screen and (max-width: 959px)": {
      //   fontFamily: "Open Sans",
      //   fontSize: 14,
      //   fontWeight: 400,
      //   lineHeight: 1.6,
      //   letterSpacing: 0.25,
      //   color: hexWithOpacity(R.colors.primary, 0.74),
      // },
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: 16,
      lineHeight: "20px",
      letterSpacing: "0.5px",
    },
    body2: {
      fontSize: 14,
      lineHeight: "17px",
      letterSpacing: "0.25px",
    },
    button: {
      fontSize: 14,
      textTransform: "uppercase",
      letterSpacing: 1.25,
      fontWeight: 600,
      lineHeight: "120%",
    },
    caption: {
      fontSize: 10,
      fontWeight: 400,
      letterSpacing: 0.4,
    },
    overline: {
      fontSize: 10,
      fontWeight: 600,
      letterSpacing: 1.5,
    },
  },
});

export default theme;
